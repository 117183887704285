import React, { useMemo, useState, useCallback } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import { format } from "date-fns";
import { FaExclamation } from "react-icons/fa";
import { debounce } from 'lodash';

import Loading from "../../../components/Loading";
import {
  GlobalFilter,
  DefaultFilterForColumn,
  StatusFilter,
  DateRangeFilter,
  dateBetweenFilterFn,
} from "./Filter";

import "../tables.scss";

const Circle = ({ color }) => {
  return (
    <div
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: `${color}`,
        display: "inline-block",
        marginRight: "6px",
        position: "relative",
        top: "-2px",
        left: "0",
      }}
    ></div>
  );
};

/**
  1. SUBMITTED -> Awating Arrival (frontend)
  2. Pending Docs -> 5Days before ETA
  3. Pending Release
  4. Released 
*/
const statusIcon = (status) => {
  switch (status.toLowerCase()) {
    case "not cleared by afb":
      return (
        <>
          <Circle color="#FF623E" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </span>
        </>
      );
    case "released":
      return (
        <>
          <Circle color="#23B574" />
          <p
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </p>
        </>
      );
    case "pending release":
      return (
        <>
          <Circle color="#DDDD32" />
          <p
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </p>
        </>
      );
    case "cbp intensive exam":
    case "cbp vax exam":
    case "cbp vacis exam":
    case "cbp docs review hold":
    case "isf hold":
      return (
        <>
          <Circle color="#3C45C8" />
          <p
            style={{
              display: "inline-block",
            }}
          >
            {status
              .toLowerCase()
              .split(" ")
              .map((i) => i[0].toUpperCase() + i.substring(1))
              .join(" ")
              .replace("Cbp", "CBP")
              .replace("Isf", "ISF")}
          </p>
        </>
      );
    case "submitted":
      return (
        <>
          <Circle color="#77C4FF" />
          <p
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            Awaiting Arrival
          </p>
        </>
      );
    case "pending documents":
      return (
        <>
          <Circle color="#EB1B23" />
          <p
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            Documents Required
          </p>
        </>
      );
    case "admissible":
    case "released":
    case "accepted":
    case "liquidated":
      return (
        <>
          <Circle color="#2336B5" />
          <p
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            Other
          </p>
        </>
      );
    default:
      return <p>{status.toLowerCase()}</p>;
  }
};

//Function that recieves the number of extra containers
const fewContainers = (containers) => {
  var formatted = "";
  for (let i = 0; i < containers?.length; i++) {
    formatted += containers[i] + ", ";
  }

  return <span>{formatted.slice(0, formatted?.length - 2)}</span>;
};

const ManyContainers = ({ containers }) => {
  var firstShipments = "";
  var otherShipments = "";
  var count = 0;
  var [opened, setOpened] = useState(false);

  if (!(typeof containers === "undefined" || containers?.length <= 0)) {
    for (let i = 0; i <= 1 && i < containers.length; i++) {
      firstShipments += containers[i] + ", ";
    }
    for (let i = 2; i < containers?.length; i++) {
      otherShipments += containers[i] + ", ";
      count += 1;
    }
  }

  return (
    <span onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
      {firstShipments.slice(0, firstShipments?.length - 2)},
      {opened ? otherShipments.slice(0, otherShipments?.length - 2) : ""}
      {!opened ? (
        <span className="container-extensor">+{count}</span>
      ) : (
        <span className="container-extensor" style={{ marginRight: "-1rem" }}>
          close
        </span>
      )}
    </span>
  );
};

const fewMasterBillLading = (master_bill_lading) => {
  var formatted = "";
  for (let i = 0; i < master_bill_lading?.length; i++) {
    formatted += master_bill_lading[i] + ", ";
  }

  return <span>{formatted.slice(0, formatted?.length - 2)}</span>;
};

const ManyMasterBillLading = ({ master_bill_lading }) => {
  var firstShipments = "";
  var otherShipments = "";
  var count = 0;
  var [opened, setOpened] = useState(false);

  if (!(typeof master_bill_lading === "undefined" || master_bill_lading?.length <= 0)) {
    for (let i = 0; i <= 1 && i < master_bill_lading.length; i++) {
      firstShipments += master_bill_lading[i] + ", ";
    }
    for (let i = 2; i < master_bill_lading?.length; i++) {
      otherShipments += master_bill_lading[i] + ", ";
      count += 1;
    }
  }

  return (
    <span onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
      {firstShipments.slice(0, firstShipments?.length - 2)},
      {opened ? otherShipments.slice(0, otherShipments?.length - 2) : ""}
      {!opened ? (
        <span className="container-extensor">+{count}</span>
      ) : (
        <span className="container-extensor" style={{ marginRight: "-1rem" }}>
          close
        </span>
      )}
    </span>
  );
};

const fewHouseBillLading = (house_bill_lading) => {
  var formatted = "";
  for (let i = 0; i < house_bill_lading?.length; i++) {
    formatted += house_bill_lading[i] + ", ";
  }

  return <span>{formatted.slice(0, formatted?.length - 2)}</span>;
};

const ManyHouseBillLading = ({ house_bill_lading }) => {
  var firstShipments = "";
  var otherShipments = "";
  var count = 0;
  var [opened, setOpened] = useState(false);

  if (!(typeof house_bill_lading === "undefined" || house_bill_lading?.length <= 0)) {
    for (let i = 0; i <= 1 && i < house_bill_lading.length; i++) {
      firstShipments += house_bill_lading[i] + ", ";
    }
    for (let i = 2; i < house_bill_lading?.length; i++) {
      otherShipments += house_bill_lading[i] + ", ";
      count += 1;
    }
  }

  return (
    <span onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
      {firstShipments.slice(0, firstShipments?.length - 2)},
      {opened ? otherShipments.slice(0, otherShipments?.length - 2) : ""}
      {!opened ? (
        <span className="container-extensor">+{count}</span>
      ) : (
        <span className="container-extensor" style={{ marginRight: "-1rem" }}>
          close
        </span>
      )}
    </span>
  );
};

const COLUMNS = [
  {
    Header: "Entry Number",
    accessor: "file_number",
    Cell: ({ value }) => (
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1rem",
        }}
      >
        {value}
      </p>
    ),
  },
  {
    Header: "Master Bill of Lading",
    accessor: "master_bill_lading",
    Cell: ({ value }) => {
      return (
        <p style={{ fontSize: "12px", width: "22rem", margin: "auto" }}>
          {value?.length > 0 ? (
            value?.length <= 2 ? (
              fewMasterBillLading(value)
            ) : (
              <ManyMasterBillLading master_bill_lading={value} />
            )
          ) : (
            <span>No master bill lading info provided.</span>
          )}
        </p>
      );
    },
  },
  {
    Header: "House Bill of Lading",
    accessor: "house_bill_lading",
    Cell: ({ value }) => {
      return (
        <p style={{ fontSize: "12px", width: "22rem", margin: "auto" }}>
          {value?.length > 0 ? (
            value?.length <= 2 ? (
              fewHouseBillLading(value)
            ) : (
              <ManyHouseBillLading house_bill_lading={value} />
            )
          ) : (
            <span>No master bill lading info provided.</span>
          )}
        </p>
      );
    },
  },
  {
    Header: "Container Number(s)",
    accessor: "containers",
    Cell: ({ value }) => {
      return (
        <p style={{ fontSize: "12px", width: "22rem", margin: "auto" }}>
          {value?.length > 0 ? (
            value?.length <= 2 ? (
              fewContainers(value)
            ) : (
              <ManyContainers containers={value} />
            )
          ) : (
            <span>No containers info provided.</span>
          )}
        </p>
      );
    },
  },
  {
    Header: "Reference Number",
    accessor: "customer_reference_no",
  },
  {
    Header: "ETA",
    accessor: "est_time_arrive",
    Cell: ({ value }) => {
      if (value != "0000-00-00 00:00:00") {
        return format(new Date(value), "MM/dd/yyyy");
      } else {
        return "";
      }
    },
    id: "date",
    filter: "dateBetween",
  },
  {
    Header: "FDA",
    accessor: "fda_review",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return statusIcon(value);
    },
  },
];

// Simple Table
export const ShipmentsTable = ({ shipments }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => shipments, [shipments]);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: data.hasOwnProperty("result") ? data?.result : data,
    },
    useSortBy
  );

  return (
    <>
      {!data.hasOwnProperty("result") ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <h1
            style={{
              marginBottom: "12.5px",
              fontSize: "1.3rem",
              color: "#303030",
            }}
          >
            Shipments
          </h1>

          <div className="table-wrapper">
            <table {...getTableBodyProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ textAlign: "center" }}
                  >
                    {headerGroup?.headers.map((column) => {
                      let align = "center";
                      if (column.Header == "Entry Number") {
                        align = "left";
                      }
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{ textAlign: align, cursor: "pointer" }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.317"
                                  height="7.317"
                                  viewBox="0 0 10.317 7.317"
                                >
                                  <g transform="translate(-188.092 -344.092)">
                                    <line
                                      x2="3.75"
                                      y2="4.5"
                                      transform="translate(189.5 345.5)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="3.75"
                                      y2="4.5"
                                      transform="translate(193.25 345.5)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.317"
                                  height="7.317"
                                  viewBox="0 0 10.317 7.317"
                                >
                                  <g transform="translate(1.408 1.408)">
                                    <line
                                      y1="4.5"
                                      x2="3.75"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="3.75"
                                      y1="4.5"
                                      transform="translate(3.75)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      stroke-width="2"
                                    />
                                  </g>
                                </svg>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="table-row" style={{ textAlign: "center" }}>
                      {row?.cells.map((cell) => {
                        let align = "center";
                        let isFile = false;
                        if (cell?.column.Header == "Entry Number") {
                          align = "left";
                          isFile = true;
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              textAlign: align,
                            }}
                          >
                            {(() => {
                              switch (cell.column.Header) {
                                case "Entry Number":
                                  return (
                                    <NavLink
                                      to={`/shipments/${cell?.row?.original?.id}`}
                                    >
                                      {cell.render("Cell")}
                                    </NavLink>
                                  );
                                case "FDA":
                                  if (
                                    cell.value == "MAY PROCEED" ||
                                    cell.value == null
                                  ) {
                                    return "";
                                  } else {
                                    return (
                                      <FaExclamation className="fail-fda" />
                                    );
                                  }

                                case "House Bill of Lading":
                                  const house =
                                    cell?.row?.original?.house_bill_lading?.join(
                                      ", "
                                    );
                                  return <p>{house}</p>;
                                default:
                                  return cell.render("Cell");
                              }
                            })()}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>

            </table>
          </div>
        </>
      )}
    </>
  );
};

const renderBillString = (input_array) => {
  let result = "";

  if (input_array?.length > 1) {
    result = `${input_array[0]} + ${input_array?.length - 1}`;
  } else if (input_array?.length == 1) {
    result = `${input_array[0]}`;
  } else {
    result = "";
  }

  return result;
};


// Search Table
export const ShipmentsTableSearch = ({ shipments, onFilterChange }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => shipments, [shipments]);
  const [searchParams] = useSearchParams();
  const sortedShipmentIds = useMemo(() => {
    if (shipments && shipments.result) {
      const sortedCopy = [...shipments.result].sort(
        (a, b) => new Date(a.est_time_arrive) - new Date(b.est_time_arrive)
      );
      return sortedCopy.map((shipment) => shipment.id);
    }
    return [];
  }, [shipments]);

  const filterTypes = useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
    }),
    []
  );

  const today = new Date();
  const last30Days = new Date(new Date().setDate(today.getDate() - 30));

  const changeFilter = useCallback(
    debounce((value) => {
      onFilterChange(value);
    }, 1000),
    [onFilterChange]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
  } = useTable(
    {
      columns,
      data: data.hasOwnProperty("result") ? data?.result : data,
      defaultColumn: { Filter: DefaultFilterForColumn },
      filterTypes: filterTypes,
      initialState: {},
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      {!data.hasOwnProperty("result") ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="filter-wrapper">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state?.globalFilter}
              setGlobalFilter={changeFilter}
            />
            <StatusFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state?.globalFilter}
              setGlobalFilter={changeFilter}
            />

            <DateRangeFilter setFilter={setFilter} />
          </div>
          <div className="table-wrapper">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ textAlign: "center" }}
                  >
                    {headerGroup?.headers.map((column) => {
                      let align = "center";
                      if (column.Header == "Entry Number") {
                        align = "left";
                      }
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{ textAlign: align, cursor: "pointer" }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.317"
                                  height="7.317"
                                  viewBox="0 0 10.317 7.317"
                                >
                                  <g transform="translate(-188.092 -344.092)">
                                    <line
                                      x2="3.75"
                                      y2="4.5"
                                      transform="translate(189.5 345.5)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="3.75"
                                      y2="4.5"
                                      transform="translate(193.25 345.5)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.317"
                                  height="7.317"
                                  viewBox="0 0 10.317 7.317"
                                >
                                  <g transform="translate(1.408 1.408)">
                                    <line
                                      y1="4.5"
                                      x2="3.75"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="3.75"
                                      y1="4.5"
                                      transform="translate(3.75)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} style={{ textAlign: "center" }}>
                      {row?.cells.map((cell) => {
                        let align = "center";
                        let isFile = false;
                        if (cell?.column.Header == "Entry Number") {
                          align = "left";
                          isFile = true;
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              textAlign: align,
                              wordWrap: "break-word",
                            }}
                          >
                            {(() => {
                              switch (cell.column.Header) {
                                case "Entry Number":
                                  return (
                                    <NavLink
                                      to={{
                                        pathname: `/shipments/${cell?.row?.original?.id}`,
                                      }}
                                      state={{
                                        filteredShipmentIds: sortedShipmentIds,
                                        filter: searchParams.get("filter"),
                                      }}
                                    >
                                      {cell.render("Cell")}
                                    </NavLink>
                                  );
                                case "FDA":
                                  if (
                                    cell?.value == "MAY PROCEED" ||
                                    cell?.value == null
                                  ) {
                                    return "";
                                  } else {
                                    return (
                                      <FaExclamation className="fail-fda" />
                                    );
                                  }
                                case "Master Bill of Lading":
                                  return cell.render("Cell");
                                case "House Bill of Lading":
                                  return cell.render("Cell");
                                default:
                                  return cell.render("Cell");
                              }
                            })()}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export const LoadingTable = () => {
  return <div className="loading-table"></div>;
};
